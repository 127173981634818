import FilesAction from "store/files/FilesAction";

class FilesReducer {
    initialState = {
        documents: []
    }

    reducer = (state = this.initialState, action) => {
        switch (action.type) {
            case FilesAction.SET_FILES:
                return {
                    ...state,
                    documents: action.payload
                }
            case FilesAction.PUSH_FILE:
                return {
                    ...state,
                    documents: [...state.documents, action.payload]
                }
            case FilesAction.DELETE_FILE:
                const fileDeleteIndex = state.documents.findIndex(file => file.id === action.payload.id);
                console.log(action.payload.id)
                return {
                    ...state,
                    documents: [
                        ...state.documents.slice(0, fileDeleteIndex),
                        ...state.documents.slice(fileDeleteIndex + 1)
                    ]

                }
            case FilesAction.UPDATE_FILE:
                const fileUpdateIndex = state.documents.findIndex(file => file.id === action.payload.id);
                console.log(action.payload)
                return {
                    ...state,
                    documents: {
                        ...state.documents,
                        [fileUpdateIndex]: action.payload
                    }
                }

            default:
                return state;
        }
    }
}

export default FilesReducer;