import React from 'react';
import {useSelector} from "react-redux";
import {Route, Redirect} from "react-router-dom";

const PrivateRoute = (props) => {
    const user = useSelector(state => state.auth.user);

    if (!user) {
        return <Redirect to={"/prihlaseni"}/>
    }

    return (
        <Route path={props.path}>
            {props.children}
        </Route>
    );
};

export default PrivateRoute;