import Admin from "pages/admin/Admin";
import Contracts from "pages/contracts/Contracts";
import AddNewDocument from "pages/documents/components/AddNewDocument";
import Documents from "pages/documents/Documents";
import Error from "pages/error/Error";
import Home from "pages/home/Home";
import Login from "pages/login/Login";
import Logout from "pages/logout/Logout";
import React from 'react';
import {withRouter, Route, Switch} from 'react-router-dom';
import PrivateRoute from "helpers/PrivateRoute";

const Routes = props => {
    return (
        <Switch>
            <Route path={"/prihlaseni"}>
                <Login/>
            </Route>
            <PrivateRoute path={"/odhlaseni"}>
                <Logout/>
            </PrivateRoute>
            <PrivateRoute path={"/admin/dokumenty/pridat-novy-dokument"}>
                <AddNewDocument/>
            </PrivateRoute>
            <PrivateRoute path={"/admin/dokumenty"}>
                <Documents/>
            </PrivateRoute>
            <Route path={"/"} exact>
                <Home />
            </Route>
            <Route>
                <Error/>
            </Route>
        </Switch>
    );
};

export default withRouter(Routes);