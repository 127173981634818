import AuthAction from "store/auth/AuthAction";

class AuthReducer {
    initialState = {
        user: null
    };

    reducer = (state = this.initialState, action) => {
        switch (action.type) {
            case AuthAction.SET_USER:
                return {
                    ...state,
                    user: action.payload
                }

            default: return state;
        }
    }
}

export default AuthReducer;