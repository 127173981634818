import React from 'react';

import Logo from 'assets/icons/logo-white.svg'
import Documents from 'assets/icons/ionic_icons/documents-outline.svg';
import Document from 'assets/icons/ionic_icons/document-attach-outline.svg';
import LogOut from 'assets/icons/ionic_icons/log-out-outline.svg';
import {Link} from "react-router-dom";

const AdminSideNavPanel = props => {
    return (
        <aside className="side-nav" id="show-side-navigation1">
            <i className="fa fa-bars close-aside hidden-sm hidden-md hidden-lg" data-close="show-side-navigation1"></i>
            <div className="heading">
                <img src={Logo} className={'rounded-0'} alt=""/>
            </div>
            <div className="info pl-3 mt">
                <h5>Správa dokumentů</h5>
            </div>
            <div className="search">
                <input type="text" placeholder="Type here"/><i className="fa fa-search"></i>
            </div>
            <ul className="categories">
                <li>
                    <img src={Documents} height={24} width={24} className={'mr-3'} alt=""/>
                    <Link to={'/admin/dokumenty'}>Dokumenty</Link>
                </li>
                <li>
                    <img src={Document} height={24} width={24} className={'mr-3'} alt=""/>
                    <Link to={'/admin/dokumenty/pridat-novy-dokument'}>Přidat nový dokument</Link>
                </li>
                <li>
                    <img src={LogOut} height={24} width={24} className={'mr-3'} alt=""/>
                    <Link to={'/odhlaseni'}>Odhlásit</Link>
                </li>
            </ul>
        </aside>
    );
};

export default AdminSideNavPanel;