import {useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import FilesAction from "store/files/FilesAction";
import app from "../firebase";
import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Card, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {db} from '../firebase';

const UploadForm = props => {
    const {register, handleSubmit, watch, setValue, reset, errors} = useForm();
    const [error, setError] = useState(false);
    const [show, setShow] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const [fileUrl, setFileUrl] = React.useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const files = useSelector(state => state.files);
    const fileInput = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        let timer;
        let redirect;
        if (submit) {
            timer = setTimeout(() => setShow(true), 1000);
            redirect = setTimeout(() => props.history.push('/admin/dokumenty'), 3000);
        }

        return () => {
            clearTimeout(timer);
            clearTimeout(redirect);
        }
    }, [submit]);

    const fileUploadHandler = () => {
        document.getElementById('fileId').click();
    }


    const onFileChange = async (e) => {
        const file = e.target.files[0];
        const storageRef = app.storage().ref();
        setUploadedFile(file.name);
        const fileRef = storageRef.child(file.name);
        await fileRef.put(file);
        setFileUrl(await fileRef.getDownloadURL());
    };

    const onSubmitHandler = async (data) => {
        setSubmit(true);
        setDisabled(true);
        const filename = data.name;

        if (!filename || !fileUrl) {
            setError(true);
            setDisabled(false);
            return;
        }
        const id = Math.random().toString(36).substr(2, 9)
        console.log(id);
        await db.collection(props.collection).doc(id).set({
            id: id,
            name: filename,
            url: fileUrl,
        });

        dispatch(FilesAction.pushFile({
            id: id,
            name: filename,
            url: fileUrl,
        }));

        setError(false);
        setDisabled(false);
        return false;
    }

    return (
        <Card className={"w-100 bg-dark text-white"}>
            <Card.Body>
                <h2 className={"text-center"}>Přidat nový dokument</h2>
                {show && (
                    !error
                        ? ( <Alert variant="success">
                             Soubor byl úspěšne nahrán.
                            </Alert>
                        ) : (
                            <Alert variant="danger">
                                Soubor se podařilo nahrát.
                            </Alert>
                    )
                )}
                <Form onSubmit={handleSubmit(onSubmitHandler)} className={'bg-dark'}>
                    <Form.Group>
                        <Form.Label>Název souboru:</Form.Label>
                        <Form.Control type={"text"} className={'bg-gray-800 text-white'} name={"name"} {...register('name')} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Vyberte soubor:</Form.Label>
                        <div className="d-flex w-100">
                            <Button onClick={fileUploadHandler} className={'primary w-25 mr-3'}>Vybrat soubor</Button>
                            <div className={'form-control bg-gray-800 text-white'}>{uploadedFile ? uploadedFile : "nebyl vybrán žádny soubor"}</div>
                        </div>
                        <Form.Control id={'fileId'} type={"file"} name={"file"} ref={fileInput} {...register('file')} onChange={onFileChange} required hidden/>
                    </Form.Group>
                    <Button className={"w-100"} type={"submit"} disabled={disabled}>Nahrát soubor</Button>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default withRouter(UploadForm);