import React from 'react';
import ConcaveCurve from "assets/icons/concave.svg";
import CableBg from 'assets/img_1.png';


const ServiceContact = props => {
    return (
        <section className="services services-download d-flex flex-column justify-content-center align-items-center text-center contact-tv position-relative"
                 style={{background: 'url('+ CableBg + ')',
                     height: '540px',
                     backgroundPosition: 'center',
                     backgroundSize: 'cover',
                     display: 'flex',
                     flexDirection: 'column',
                     justifyContent: 'center',
                     alignItems: 'center'
                 }}>
            <div className="container" data-aos="zoom-in"
                 data-aos-duration="500">
                <h2 className="download-title text-white"><b>Kontaktujte nás pro nezávazné poradenství</b></h2>
                <div className="row">
                    <a rel={'noreferrer'} href="https://elnet-group.cz/kontakt"
                       className="button button-main mx-auto mt-5">Kontaktovat nyní</a>
                </div>
            </div>
            <div className="concave-curve">
                <img src={ConcaveCurve} width={100} alt=""/>
            </div>
        </section>
    );
};

export default ServiceContact;