import React from 'react';
import Logo from 'assets/icons/logo2.svg'

const Footer = props => {
    return (
        <>
            <footer id={'footer'} className="footer Footer p-5">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4">
                        <img className="footer-logo"
                             src={Logo} height={120} alt="logo"/>
                            <div className="basic-text">
                                Našim cílem je poskytovat klientům kvalitu a komfort za rozumnou cenu.
                            </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 text-right">
                            <h2 className="section__subheading"><b>Kontakt</b></h2>
                            <div className="basic-text">
                                Elnet Group s.r.o. <br/>
                                Kundratka 1904/20 <br/>
                                180 00 Praha 8, Libeň
                            </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 text-right">
                        <h2 className="section__subheading">Navigace</h2>
                        <ul className="footer-nav">
                            <li className="menu-item">
                                <a rel={'noreferrer'} href="https://elnet-group.cz/"
                                   className="menu-link"><b>Internetové připojení</b></a>
                            </li>
                            <li className="menu-item">
                                <a rel={'noreferrer'} href="https://elnet-group.cz/televize/"
                                   className="menu-link"><b>Online televize</b>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a rel={'noreferrer'} href="https://elnet-group.cz/servis/"
                                   className="menu-link"><b>IT služby</b></a>
                            </li>
                            <li className="menu-item">
                                <a rel={'noreferrer'} href="https://elnet-group.cz/kontakt/"
                                   className="menu-link"><b>Kontakt</b></a>
                            </li>
                            <li className="menu-item">
                                <a rel={'noreferrer'} href="https://elnet-group-dokumenty.sitenode.sk"
                                   className="menu-link"><b>Dokumenty</b></a>
                            </li>



                        </ul>
                    </div>
                </div>

            </footer>
            <div className="attribute d-flex justify-content-center">
                <p>Copyright © 2011 - 2021 elnet-group.cz. Všechna práva vyhrazena</p>
            </div>
        </>
    );
};

export default Footer;