import React from 'react';

import ConvexCurve from 'assets/icons/konvexny.svg';
import Backgorund from 'assets/img.png';

const Header = props => {
    return (
        <header id="header" className="header-packs header-services"
                style={{
                    background: 'url('+ Backgorund +')',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
        }}>

            <div className="container header-content-packs text-center text-white">

                {/*<h1 className="header-content-packs__title" data-aos="fade-up"*/}
                {/*    data-aos-duration="500" data-aos-delay="150">Dokumenty</h1>*/}
                <h1 className={'my-5 text-center'} style={{fontSize: '62px'}}><b>Dokumenty</b></h1>


                <p className="header-services-text" data-aos="fade-up"
                   data-aos-duration="500" data-aos-delay="250"></p>

            </div>

            <div className="convex-curve">
                <img src={ConvexCurve} width={100} alt="convex curve"/>
            </div>
        </header>
    );
};

export default Header;