import Loader from "components/Loader";
import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import AuthAction from "store/auth/AuthAction";

const Logout = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AuthAction.logOut()).then(r => {
            props.history.push('/prihlaseni');
        });
    }, []);

    return <Loader/>
};

export default withRouter(Logout);