import AdminTableData from "components/AdminTableData";
import TableData from "components/TableData";
import React from 'react';
import {db} from '../../firebase';

const Documents = props => {
    return (
        <div className="Documents">
            <AdminTableData db={db} collection={'documents'}/>
        </div>
    );
};

export default Documents;