import UploadForm from "components/UploadForm";
import React from 'react';

const AddNewDocument = props => {
    return (
        <div className="AddNewDocument">
            <UploadForm collection={'documents'}/>
        </div>
    );
};

export default AddNewDocument;