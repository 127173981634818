import React from 'react';
import {Link} from "react-router-dom";

const Error = props => {
    return (
        <div className="d-flex align-items-center justify-content-center Error text-center" style={{minHeight: '100vh'}}>
            <div>
                <h1>404 chyba</h1>
                <h2>Stránka nebyla nalezena</h2>
                <Link to={'/'}>Zpět na stránku dokumenty</Link>
            </div>
        </div>
    );
};

export default Error;