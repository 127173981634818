import {applyMiddleware, createStore, compose} from "redux";
import reducers from "./reducers";
import {loadState, saveState} from "./state";
import thunk from "redux-thunk";
const persistedState = loadState();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, persistedState, composeEnhancers(applyMiddleware(thunk)));

store.subscribe(() => {
    saveState(store.getState());
});

export default store;