import AdminSideNavPanel from "components/AdminSideNavPanel";
import Footer from "components/Footer";
import FrontNav from "components/FrontNav";
import Header from "components/Header";
import Loader from "components/Loader";
import Navigation from "components/Navigation";
import ServiceContact from "components/ServiceContact";
import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Jumbotron, Nav, Navbar, NavDropdown, Row} from 'react-bootstrap';
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {useLocation} from 'react-router';

//create your forceUpdate hook
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const FrontLayout = props => {
    const userToken = useSelector(state => state.auth.user?.stsTokenManager?.accessToken);
    const location = useLocation();
    const admin =
        location.pathname === '/admin'
        || location.pathname === '/admin/dokumenty'
        || location.pathname === '/admin/dokumenty/pridat-novy-dokument';
    const forceUpdate = useForceUpdate();
    const isHomePage = location.pathname === '/';

    // useEffect(() => {
    //     if (userToken !== null) {
    //         console.log('useEffect front layout')
    //     }
    // }, [userToken]);

    let login = null;
    if(location.pathname === '/prihlaseni') {
        login = props.children;
    } else {
        login = (
            <>
                <FrontNav userToken={userToken}/>
                <Header/>
                <Container fluid style={{ margin: isHomePage ? '75px 0' : '0'}}>
                    {props.children}
                </Container>
                <ServiceContact/>
                <Footer/>
            </>
        )
    }


    return (
        <div className="FrontLayout">
            {userToken !== null && admin ? (
                <>
                    <AdminSideNavPanel/>

                    <section id="contents" style={{minHeight: '100vh'}} className={'position-relative'}>
                        <Navigation/>
                        <Container fluid>
                            {props.children}
                        </Container>
                    </section>
                </>
                ) : login}
        </div>
    );
};

export default withRouter(FrontLayout);