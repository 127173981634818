import LoginForm from "pages/login/components/LoginForm";
import React from 'react';
import {Container} from "react-bootstrap";

const Login = props => {
    return (
        <Container
            className="d-flex align-items-center justify-content-center"
            style={{minHeight: '100vh'}}>
            <LoginForm />
        </Container>
    );
};

export default Login;