import React, {useEffect} from 'react'
import Routes from "layouts/Routes";
import {useDispatch} from "react-redux";
import FilesAction from "store/files/FilesAction";
import FrontLayout from "./layouts/FrontLayout";
import './App.scss';
import {db} from "./firebase";
// import 'assets/styles/style.css';

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const usersCollection = await db.collection('documents').get();
            // setData(
            //     usersCollection.docs.map((doc) => {
            //         return doc.data();
            //     })
            // );
            dispatch(FilesAction.set_files(usersCollection.docs.map((doc) => {
                return doc.data();
            })));
        };
        fetchData();
    }, [])

  return (
    <div className="App">
      <FrontLayout>
            <Routes/>
      </FrontLayout>
    </div>
  );
}

export default App;
