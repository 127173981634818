import LoginForm from "pages/login/components/LoginForm";
import React from 'react';
import useFetchData from "helpers/useFetchData";
import Loader from "components/Loader";
import {Container, Table} from "react-bootstrap";
import CenikSluzeb from 'assets/pdf/Ceník služeb.pdf'
import KontaktyProHlaseni from 'assets/pdf/Kontakty pro hlášení poruch.pdf';
import ParmaterRouterMikrotik from 'assets/pdf/Parametry Router Mikrotik.pdf';
import SmlouvaDepotPrague2 from 'assets/pdf/Smlouva a Všeobecné Obchodní Podmínky v platnosti od 21.4.2020 - Depot Prague Malešice.pdf'
import SmlouvaHostavice from 'assets/pdf/Smlouva o poskytování služby přístupu k internetu v platnosti od 1.6.2021 - Hostivice a Hostavice.pdf'
import SmlovaHostavice2 from 'assets/pdf/Smlouva a Všeobecné Obchodní Podmínky v platnosti od 21.4.2020 - Hostivice a Hostavice.pdf';
import SmlouvaDepotPrague from 'assets/pdf/Smlouva o poskytování služby přístupu k internetu v platnosti od 1.6.2021 -  Depot Prague Malešice.pdf';
import SpecifikacePripojeniInternetu from 'assets/pdf/Specifikace služby připojení k internetu.pdf';
import VOP1 from 'assets/pdf/Všeobecné Obchodní Podmínky platné od 1.6.2021 - Hostivice a Hostavice.pdf';
import VOP2 from 'assets/pdf/Všeobecné Obchodní Podmínky platné od 1.6.2021 - Prague Depot Malešice.pdf';
import {useSelector} from "react-redux";


const TableData = props => {
    const files = useSelector(state => state.files);

    if (!files) {
        return <Loader/>
    }

    return (
        <Container fluid className={'rounded'}>
            <Table striped bordered hover className={'rounded'} variant="light">
                <thead>
                <tr>
                    <th>Název souboru</th>
                    <th>Typ</th>
                </tr>
                </thead>
                <tbody>
                {files.documents?.map((data) => {
                    return (
                        <tr key={data.id}>
                            <td>{data.name}</td>
                            <td><a href={data.url} className={'pdf'} rel={'noreferrer'} target={"_blank"}>PDF</a></td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>
        </Container>
    );
};

export default TableData;