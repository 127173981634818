import SubmitIcon from "assets/icons/ionic_icons/checkmark-outline.svg";
import EditIcon from "assets/icons/ionic_icons/create-outline.svg";
import DeleteIcon from "assets/icons/ionic_icons/trash-outline.svg";
import CloseIcon from 'assets/icons/ionic_icons/close-outline.svg';
import {useDispatch} from "react-redux";
import FilesAction from "store/files/FilesAction";
import {db} from "../firebase";
import React, {useRef, useState} from 'react';
import {useForm} from "react-hook-form";

const AdminTableRow = props => {
    const [disabled, setDisabled] = useState(true);

    const defaultValues = {
        id: props.data.id,
        name: props.data.name,
        url: props.data.url,
    }
    const {register, handleSubmit, watch, setValue, reset, errors} = useForm({
        defaultValues: defaultValues
    });
    const dispatch = useDispatch();

    const editDocumentHandler = () => {
        setDisabled(false);
    }

    const cancelEditHandler = () => {
        setDisabled(true);
    }

    const updateFileHandler = async (data, e) => {
        setDisabled(true);
        await db.collection('documents').doc(data.id).update({
            name: data.name,
        }).then(r => console.log('then update'))

        // dispatch(FilesAction.updateFile({
        //     id: data.id,
        //     name: data.name,
        //     url: data.url,
        // }));
    }

    const deleteDocumentHandler = async (data) => {
        await db.collection('documents').doc(data.id).delete().then(r => {
            dispatch(FilesAction.deleteFile({id: data.id}))
            alert('smazano');
        });
    };

    return (
        <tr>
            <td>
                <form>
                    <input type="text" className={!disabled ? 'inputUndisabled form-control' : 'inputDisabled w-100'} {...register('name')} name={'name'}  disabled={disabled}/>
                    <input type="hidden" name={'id'}  {...register('id')}/>
                    <input type="hidden" name={'url'}  {...register('url')}/>
                </form>
            </td>
            <td>
                {disabled ? (
                    <img onClick={editDocumentHandler} className={'cursor'} src={EditIcon} height={24} width={24} alt=""/>
                ) : (
                    <div className={'d-flex justify-content-center'}>
                        <img onClick={cancelEditHandler} className={'cursor'} src={CloseIcon} height={24} width={24} alt=""/>
                        <img onClick={handleSubmit(updateFileHandler)} className={'cursor'} src={SubmitIcon} height={24} width={24} alt=""/>
                    </div>
                )}
            </td>
            <td>
                <img src={DeleteIcon} onClick={handleSubmit(deleteDocumentHandler)} className={'cursor'} height={24} width={24} alt=""/>
            </td>
        </tr>
    );
};

export default AdminTableRow;