import React from 'react';
import {Nav, Navbar} from "react-bootstrap";
import Logo from 'assets/icons/logo1.svg';
import InternetIcon from 'assets/icons/ikonaInternetFill.svg';
import TVicon from 'assets/icons/ikonaTeleviziaFill.svg';
import ServicIcon from 'assets/icons/servisFill.svg';
import KontaktIcon from 'assets/icons/kontaktFill.svg';
import Documents from 'assets/icons/dokumenty-2-fill.svg';

const FrontNav = props => {
    return (
        <Navbar className={"navbar-dark navbarFront px-4"} expand="lg">
            <Navbar.Brand href="#home" className={'py-2'}>
                <img src={Logo} height={70} width={120} alt=""/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-auto">
                    <Nav.Link className={"link d-flex mr-4"} href="https://elnet-group.cz/">
                        <img src={InternetIcon} className={'mr-3'} height={30} width={30} alt=""/>
                        <span className={'mt-1'}>Internetové připojení</span></Nav.Link>
                    <Nav.Link className={"link d-flex mr-4"} href="https://elnet-group.cz/televize/">
                        <img src={TVicon} className={'mr-3'} height={30} width={30} alt=""/>
                        <span className={'mt-1'}>Televize</span></Nav.Link>
                    <Nav.Link className={"link d-flex mr-4"} href="https://elnet-group.cz/servis/">
                        <img src={ServicIcon} className={'mr-3'} height={30} width={30} alt=""/>
                        <span className={'mt-1'}>Servis</span></Nav.Link>
                    <Nav.Link className={"link d-flex mr-4"} href="https://elnet-group.cz/kontakt/">
                        <img src={KontaktIcon} className={'mr-3'} height={30} width={30} alt=""/>
                        <span className={'mt-1'}>Kontakt</span></Nav.Link>
                    <Nav.Link className={"link d-flex mr-4"} href="https://elnet-group-dokumenty.sitenode.sk">
                        <img src={Documents} className={'mr-3'} height={30} width={30} alt=""/>
                        <span className={'mt-1'}>Dokumenty</span></Nav.Link>
                    {/*{props.userToken ? (*/}
                    {/*    <Nav.Link className={"link d-flex"} href="https://elnet-group-dokumenty.sitenode.sk/admin/dokumenty">*/}
                    {/*        <img src={ServicIcon} className={'mr-3'} height={30} width={30} alt=""/>*/}
                    {/*        <span className={'mt-1'}>Administrace</span>*/}
                    {/*    </Nav.Link>*/}
                    {/*) : null}*/}
                </Nav>
                <div className="contact d-flex flex-column">
                    <a rel={'noreferrer'} href="tel:+420777801910">+420 777 801 910
                        </a>
                    <span>Po - Pa: 9:00 - 17:00</span>
                </div>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default FrontNav;