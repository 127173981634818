import {createAction} from "helpers/createAction";
import { auth } from "../../firebase";

class AuthAction {
    static SET_USER = 'AuthAction.SetUser';

    static login(email, password) {
        return dispatch => {
            return auth.signInWithEmailAndPassword(email, password).then(r => {
                dispatch(AuthAction.setUser(r.user));
            });
        }
    }

    static logOut() {
        return dispatch => {
            return auth.signOut().then(r => {
                dispatch(AuthAction.setUser(null));
            });
        }
    }

    static setUser(user) {
       return createAction(AuthAction.SET_USER, user);
    }
}

export default AuthAction;