import {createAction} from "helpers/createAction";

class FilesAction {
    static SET_FILES = "FilesAction.SetFiles";
    static PUSH_FILE = "FileAction.PushFile";
    static DELETE_FILE = "FilesAction.DeleteFile";
    static UPDATE_FILE = "FilesAction.UpdateFile";

    static set_files(files) {
        return createAction(FilesAction.SET_FILES, files);
    }

    static pushFile(file) {
        return createAction(FilesAction.PUSH_FILE, file);
    }

    static deleteFile(file) {
        return createAction(FilesAction.DELETE_FILE, file);
    }

    static updateFile(file) {
        return createAction(FilesAction.UPDATE_FILE, file);
    }
}

export default FilesAction;